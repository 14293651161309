<template>
    <div v-if="!isPending && !isCalculating && dataSource" class="files-list-box wrapperForm">
        <v-row dense style="margin-bottom:0!important;">
            <v-col cols="auto" class="section-wrapper attachments-left-menu">
                <v-card
                    class="cloud-file-list-box"
                    flat
                    width="300"
                >
                    <v-card-text>
                        <div class="form-box-title title-with-right-part alm-title">
                            <span>{{$t('Разделы_отчета')}}</span>
                        </div> 
                        <div class="cloud-items-container">
                            <!--<div v-if="" class="cfl-placeholder" >{{$t('Нет_файлов')}}</div>-->
                            <v-row
                                class="cfl-group-wrap"
                                no-gutters
                            >
                                <v-col cols="12" sm="12" md="12" class="cfl-group-items">
                                    <v-row dense>
                                        <v-col 
                                            cols="12" sm="12" md="12"
                                            v-for="(section, sectionKey) in this.dataSource.sections"
                                            :key="sectionKey"
                                            @click="changeTabSection(sectionKey)"
                                            :class="[sectionKey == sectionModel ? 'cfl-item-active' : '', 'cfl-item-wrap']"
                                        >
                                            <div class="cfl-item" v-tooltip.left-center="localized(section.title)">
                                                <div class="file-icon">
                                                    <v-icon>
                                                        mdi-file-outline
                                                    </v-icon>
                                                </div>
                                                <div class="cfl-info">
                                                    <div class="cfl-abs">
                                                        <div class="cfl-filename" style="margin-top:12px;">{{ localized(section.title) }}</div>
                                                        <div>
                                                            <div class="cfl-fileinfo">
                                                                <!--<div v-if="attachment.Message" class="cfl-err-msg">{{ attachment.Message }}</div>
                                                                <span v-else-if="attachment.Progress">{{ attachment.Progress }}</span>
                                                                <span v-else>{{ parseInt(attachment.Length) ? `${(parseFloat(attachment.Length) / (1024 * 1024)).toFixed(2)} ${$t('МБ')}` : '-' }}</span>  -->                                              
                                                            </div>
                                                            <!-- <div class="cfl-status"></div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--<div class="cfl-actions"
                                                    v-if="!attachment.Progress"
                                                >
                                                    <span 
                                                        v-tooltip.left-center="$t('Информация_о_файле')"
                                                        @click="openInfoModal(attachment)"
                                                    >
                                                        <i class="fas fa-info-circle dark-gray-color"></i>
                                                    </span>
                                                    <span 
                                                        v-if="!attachment.Message && !attachmentIsReportTemplate"
                                                        class="download-file" 
                                                        v-tooltip.left-center="$t('Загрузить')"
                                                        @click.stop="loadAttchment(attachment)"
                                                    >
                                                        <i class="fas fa-download dark-gray-color"></i>
                                                    </span>
                                                    <span
                                                        v-if="!attachment.IsReadOnly && !attachmentIsReportTemplate && ( !isDocument || isExistedDocument ) && !isDocumentIsArchive" 
                                                        class="delete-file"
                                                        v-tooltip.left-center="$t('Удалить')"
                                                        @click.stop="deleteAttchment(attachment)"
                                                    >
                                                        <i class="fas fa-trash dark-gray-color"></i>
                                                    </span>
                                                </div>-->
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="section-wrapper">
                <v-card 
                    class="file-preview"
                    flat
                >
                    <v-card-text>
                        <div class="form-box-title">{{ localized(dataSource.sections[sectionModel].title) }}</div>
                        <v-tabs-items
                            v-model="sectionModel"
                            class="tab-content-panel"
                        >
                            <v-tab-item 
                                v-for="(section, sectionKey) in this.dataSource.sections"
                                :key="sectionKey"
                            >    
                                <template v-for="(item, name, index) in section">                                                                                                          
                                    <component 
                                        v-if="item.type && item.visible && !isViewMode"
                                        :is="getComponentName(item)" 
                                        :key="'c'+'s'+sectionKey+'i'+index"
                                        :label="getText(item.title)" 
                                        :value="item.value" 
                                        @input="e => updateDataSource({ property: `sections[${sectionKey}].${name}.value`, value: e })" 
                                        required
                                        :rules="item.type != 'bool' ? getRules(item) : null"
                                        :items="item.type == 'select' ? getItems(item) : null"                                                            
                                    /> 
                                    
                                    <v-simple-table 
                                        v-else-if="item.header && item.data"
                                        :key="'st'+'s'+sectionKey+'i'+index"
                                        :id="'st'+'s'+sectionKey+'i'+index"
                                        class="customization-list-table bordered-table no-padding-td-table white-thead-table table-with-sticky-header">
                                        <template v-slot:default>
                                            <thead>                                                            
                                                <tr 
                                                    v-for="(trdata, trKey) in getHeadTRData(item)" 
                                                    :key="'hs'+sectionKey+'tr'+trKey"
                                                >
                                                    <th 
                                                        v-for="(thdata, thKey) in trdata" 
                                                        :key="'hs'+sectionKey+'tr'+trKey+'th'+thKey"
                                                        :rowspan="thdata.rowspan"
                                                        :colspan="thdata.colspan"
                                                    >{{ localized(thdata.title) }}
                                                        <i v-if="thdata.description" class="fas fa-info" v-tooltip.left-center="localized(thdata.description)"></i>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr 
                                                    v-for="(trdata, trKey) in getBodyTRData(item)" 
                                                    :key="'bs'+sectionKey+'tr'+trKey"
                                                    :class="getTrClass(trdata)"
                                                >
                                                    <td
                                                        v-for="(tddata, tdKey) in trdata.values"
                                                        :key="'ds'+sectionKey+'tr'+trKey+'td'+tdKey"
                                                    >
                                                        <template v-if="!!getDataType(item, tdKey) && !getReadonlyValue(trdata.readonly, tdKey)">
                                                            <template v-if="!isViewMode">
                                                                <v-text-field
                                                                    hide-details
                                                                    outlined
                                                                    dense
                                                                    class="cust-inputnumber"
                                                                    min="0"
                                                                    :value="tddata"
                                                                    @keypress="filterNumberField()" 
                                                                    @paste.prevent
                                                                    @input="e => {
                                                                        updateDataSource({ property: `sections[${sectionKey}].${name}.data[${trKey}].values[${tdKey}]`, value: e });                                                                                                                     
                                                                        calculate({ sectionIdx:sectionKey, tableName:name, rowChangedIdx: trKey, colChangedIdx: tdKey });
                                                                    }" 
                                                                >
                                                                </v-text-field>
                                                            </template>
                                                            <template v-else>
                                                                <div class="read-td-data">
                                                                    {{ localized(tddata) }}
                                                                </div>
                                                            </template>
                                                        </template> 
                                                        <template v-else-if="typeof tddata === 'string' && tddata.startsWith('this.')">
                                                            <div :key="updateKey">
                                                                {{ trdata.calculated ? trdata.calculated[tdKey] : '' }}
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            {{ localized(tddata) }}
                                                        </template>
                                                    </td>
                                                </tr>                                                         
                                            </tbody>
                                        </template>  
                                    </v-simple-table>

                                    <v-row 
                                        v-else
                                        no-gutters 
                                        :key="'c'+'s'+sectionKey+'i'+index"    
                                    >
                                            <template v-if="item.type == 'label'">
                                                <v-col cols="12" sm="12" md="12">
                                                    <label class="f-label">{{ item.title ? getText(item.title) : ''}}</label>
                                                </v-col>
                                            </template>
                                            <template v-else>
                                                <v-col cols="12" sm="12" md="4">
                                                    <label class="f-label">{{ item.title ? getText(item.title) : ''}}</label>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="8">
                                                    <div class="onlyReadData">
                                                        <template v-if="item.type == 'bool'">
                                                            {{ item.value ? $t("Да") : $t("Нет") }}
                                                        </template>
                                                        <template v-else-if="item.type == 'date'">
                                                            {{ formattedDate(item.value)  }}
                                                        </template>
                                                        <template v-else>
                                                            {{ item.value }}
                                                        </template>
                                                    </div> 
                                                </v-col>
                                            </template>
                                    </v-row>
                                </template>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import sys from '@/services/system';

export default {
    name: "report-interaction-tab",
    oldVal: null,
    props: {
        id: {
            type: String,
            default: null,
        },
        rawScheme: {
            type: String,
            default: null,
        },
        rawData: {
            type: String,
            default: null,
        },
        isViewMode: {
            type: Boolean,
            default: true,
        }
    },
    components: { },
    data() {
        return {
            sectionModel: 0,
            contBox: document.getElementsByClassName('content-box')[0],
            offsetContBox: 0,
            isCalculating: false   
        }
    },
    computed: {
        ...mapGetters('reportsgeneration/reportinteraction', { dataSource: 'getDataSource', isPending: 'isPending', isActive: 'isActive', updateKey: 'getUpdateKey' }),        
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        ...mapActions('global/actionsource', ['setActiveTabName']),
        ...mapActions('reportsgeneration/reportinteraction', ['updateDataSource', 'loadDataSource', 'resetUpdateKey']),
        ...mapMutations('reportsgeneration/reportinteraction', { setDataSource: 'SET_DATASOURCE' }),
        async update() {
            if (this.$parent.isActive)
                this.setActiveTabName('report-interaction-tab');
            
            this.setOverlayVisible({ visible: true });

            //load dataSource from passed props
            if (this.rawScheme)
            {
                this.isCalculating = true
                await this.loadDataSource({ id: null, description: { rawScheme: this.rawScheme, rawData: this.rawData } });
                //eslint-disable-next-line no-unused-vars
                for(const [sectionIdx, section] of this.dataSource.sections.entries())
                for (let key of Object.keys(section))
                {
                    let item = section[key];
                    if (item.data && item.header) { //tables
                        this.calculate({ sectionIdx, tableName:key });
                    }
                }
                this.isCalculating = false
            }

            //ToDo: load dataSource from ReportQueryId?

            this.setOverlayVisible({ visible: false });
        },
        heightControlMethod() {
            var parent = document.getElementsByClassName('content-box')[0];
            var top_btns_bar_obj = parent.getElementsByClassName('top-btns-bar');
            var white_panel_obj = parent.getElementsByClassName('white-panel-items');
            var tab_selector_panel_obj = parent.getElementsByClassName('tab-selector-panel');
            var alm_title_obj = parent.getElementsByClassName('alm-title');
            var cloud_items_container_obj = parent.getElementsByClassName('cloud-items-container');
            
            var parent_h = parent.clientHeight;
            var toolbar_h = top_btns_bar_obj.length > 0 ? top_btns_bar_obj[0].clientHeight : 0;
            var white_panel_h = white_panel_obj.length > 0 ? white_panel_obj[0].clientHeight : 0;
            var tabs_margin_top = white_panel_obj.length > 0 ? 10 : 0;
            var tab_selector_panel_h = tab_selector_panel_obj.length > 0 ? tab_selector_panel_obj[0].clientHeight : -10;
            var alm_title_h = alm_title_obj.length > 0 ? alm_title_obj[0].clientHeight : 0;
            //для меню слева
            if(Array.isArray(cloud_items_container_obj) && cloud_items_container_obj.length > 0)
                cloud_items_container_obj[0].style.height = (parent_h - (toolbar_h + white_panel_h + tabs_margin_top + tab_selector_panel_h + alm_title_h + 50)) + "px";
        },
        changeTabSection(sectionIndex){
            this.sectionModel = sectionIndex;
        },
        localized(val) {
            if (val == null)
                return val;
            if (typeof val === 'string') 
                return val;            
            else if (val[this.$i18n.locale] !== undefined)            
                return val[this.$i18n.locale];

            return null;
        },
        eval(strCode) {
            return eval(strCode);
        },
        getComponentName(field) {
            switch(field.type) {
                case "bool": return "v-field-edit-bool";
                case "string": return "v-field-edit-text";
                case "date": return "v-field-edit-date";
                case "number": return "v-field-edit-number";
                case "select": return "v-field-edit-select";
                case "label": return "v-label"
            }
        },
        getText(val){
            return Object.prototype.hasOwnProperty.call(val, "kk") && Object.prototype.hasOwnProperty.call(val, "ru") 
            ? this.localized(val)
            : typeof val === 'string' && val.startsWith('this') 
                ? eval(val) : this.$t(val);
        },
        getRules(field) {         
            let rules = !field.rules 
                ? [] 
                : field.rules.reduce((rules, key) => { rules.push(eval(this.dataSource.rules[key])); return rules }, []);
            return rules;
        },
        getItems(field) {
            if (field.alias && this.dataSource.referenses[field.alias])
            {
                let items = Object.keys(this.dataSource.referenses[field.alias]).reduce((acc, key) => {
                    acc.push({id: key, value: this.localized(this.dataSource.referenses[field.alias][key])});
                    return acc;
                }, []);

                return items;
            }
            
            return null;
        },  
        getHeadTRData(section) {
            let data = [];
            let hasNextLevel = true;
            let items = section.header;
            let level = 0;            
            let deepLevel = this.getDeepLevel(items);

            while(hasNextLevel) {
                level++;

                let result = items.reduce((acc, item) => {
                    acc.push({title: item.title, description: item.description, rowspan: (!Array.isArray(item.items) || item.items?.length == 0) && deepLevel - level + 1 > 1 ? deepLevel - level + 1 : null , colspan: this.getColspan(item)});
                    return acc;
                }, []);

                data.push(result);

                items = items.reduce((acc, item) => {
                    if (Array.isArray(item.items) && item.items?.length > 0)
                        item.items.forEach(i => acc.push(i));

                    return acc;
                }, []);

                hasNextLevel = items.length > 0;
            }

            return data;
        },
        getColspan(v){
            let result =  v.items?.length > 0 
            ? this.getWidthLevel(v.items)
            : null;            

            return result;
        },
        getDeepLevel(data) {
            let result = 0;
            let hasNextLevel = true;
            let items = data

            while(hasNextLevel) {
                result++;
                items = items.reduce((acc, item) => {
                    if (item.items?.length > 0)
                        item.items.forEach(i => acc.push(i));
                    return acc;
                }, []);

                hasNextLevel = items.length > 0;
            } 
            
            return result;
        },
        getWidthLevel(data) {
            let result = data.filter(i => !Array.isArray(i.items) || i.items.length == 0).length;
            let hasNextLevel = true;
            let items = data

            while(hasNextLevel) {
                items = items.reduce((acc, item) => {
                        item.items?.forEach(i => acc.push(i));
                    return acc;
                }, []);

                hasNextLevel = items.length > 0;
                
                result += items.filter(i => !Array.isArray(i.items) || i.items.length == 0).length
            } 
            
            return result;
        },
        getBodyTRData(section){            
           return section.data;
        },
        getDataType(section, key) {
            let items = section.header;
            let result = items.filter(i => !Array.isArray(i.items) || i.items.length == 0);
            let hasNextLevel = true;
            
            while(hasNextLevel) {
                items = items.reduce((acc, item) => {
                        item.items?.forEach(i => acc.push(i));
                    return acc;
                }, []);

                hasNextLevel = items.length > 0;
                
                items.filter(i => !Array.isArray(i.items) || i.items.length == 0).forEach(i => result.push(i));
            } 
            return result[key].type;
        },
        getComponents(section){
            return Object.keys(section).reduce((a, k) => {

                if (k != 'title' && k != 'description') {
                    a.set(k, section[k]);
                }

                return a;
            }, new Map([]));
        },
        getTrClass(trdata){
            return trdata.readonly && trdata.values.some(v => typeof v === `string` && v.startsWith(`this.`)) ? 'calculate-tr' :
            trdata.readonly && !trdata.values.some(v => typeof v === `string` && v.startsWith(`this.`)) ? 'readonly-tr' :
            null;
        },
        filterNumberField: function(evt) {
            evt = (evt) ? evt : window.event;
            let expect = evt.target.value.toString() + evt.key.toString();
            let caretPositionStart = evt.target.selectionStart;
            let caretPositionEnd = evt.target.selectionEnd;
            if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
                evt.preventDefault();
            } else if (expect.length > 1 && evt.key == "0" && caretPositionStart == 0 && caretPositionStart == caretPositionEnd) {
                evt.preventDefault();
            } else if (expect.length > 1 && evt.target.value == 0 && caretPositionStart == 1) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        formattedDate(value) {
            return sys.dateFormat(value, 'DD.MM.YYYY') ?? "";
        },
        scrollContBoxMethod(){
            let cloud_file_list_obj = this.contBox.getElementsByClassName('cloud-file-list-box');
            let cloud_file_list = cloud_file_list_obj.length > 0 ? cloud_file_list_obj[0] : [];
            
            let sticky_tables_obj = this.contBox.getElementsByClassName('table-with-sticky-header');
            let sticky_tables = sticky_tables_obj.length > 0 ? sticky_tables_obj : [];

            let contBoxScrollTop = this.contBox.scrollTop - 194;
            //фиксируем шапку таблицы, пока таблица в области видимости
            for (var i = 0; i < sticky_tables.length; i++) {

                let offsetTop = sticky_tables[i].offsetTop;
                let offsetBottom = sticky_tables[i].offsetTop + sticky_tables[i].clientHeight;
                let seeingTable = document.getElementById(sticky_tables[i].id);
                let seeingTableThead = document.getElementById(sticky_tables[i].id).getElementsByTagName('thead')[0];
                if(contBoxScrollTop >= offsetTop && contBoxScrollTop <= offsetBottom){
                    seeingTable.style.position = "inherit";
                    seeingTableThead.style.top = (contBoxScrollTop - offsetTop - 1) + "px";
                }else{
                    seeingTable.style.position = "relative";
                    seeingTableThead.style.top = 0;
                }
                //при скролировании до первой таблицы, у главного контейнера меняем внутр. тень
                if(contBoxScrollTop >= sticky_tables[0].offsetTop){
                    this.contBox.classList.add("cBoxForStickable");
                }else{
                    this.contBox.classList.remove("cBoxForStickable");
                }
            }
            //фиксируем левое меню
            cloud_file_list.style.height = (this.contBox.clientHeight - 30) + 'px';
        },
        getReadonlyValue(value, index)
        {
            if (typeof value == 'boolean')
                return value;
            else if (Array.isArray(value) && value.length > 0 && value.length - 1 >= index)
                return typeof value[index] == 'boolean' ? value[index] : true;
            else true;
        },

        //calculation methods
        getFuncArrayParams(value)
        {
            let match = value.match(/[^;()]+(?:\([^()]*(?:\([^()]*\)[^()]*)*\))?/g);
            if(Array.isArray(match))
                return match;

            return null;
        },
        fillDataCols(data, dataCols)
        {
            for (const element of data)
            {
                if(element.export)
                    dataCols.push(element);

                if(Array.isArray(element.items))
                {                    
                    if (element.items.length == 0)
                        dataCols.push(element);
                    else
                    this.fillDataCols(element.items, dataCols);
                }
            }
        },
        calculate(data)
        {
            try {
                //data {sectionIdx, tableName, rowChangedIdx, colChangedIdx}
                // eslint-disable-next-line no-unused-vars
                for (const [dataIdx, dataElement] of this.dataSource.sections[data.sectionIdx][data.tableName].data.entries())
                {
                    if ((typeof dataElement.readonly === 'boolean' && dataElement.readonly) || (Array.isArray(dataElement.readonly) && dataElement.readonly.some((e) => !!e)))
                        // eslint-disable-next-line no-unused-vars
                        for(const [valueIdx, valueElement] of dataElement.values.entries())
                            if (typeof valueElement === 'string' && valueElement.startsWith('this.'))
                            {
                                let result = eval(this.injectParams(valueElement, data.sectionIdx, data.tableName));
                                this.updateDataSource({ property: `sections[${data.sectionIdx}].${data.tableName}.data[${dataIdx}].calculated[${valueIdx}]`, value: result });
                            }
                }

                this.resetUpdateKey();

            } catch (error) {
                console.log('calculate catch', error)                
            }
        },
        injectParams(value, sectionIdx, tableName){
            //TODO реализовать инъекциию во все встречающиеся методы getValueOf, getSumOf, учитывая ранее произведенные инъекции
            //модернизация позволит использовать стандартные функции библиотеки Math
            return value.replace(/(\w+\.\w+)\((.+?)\)/, `$1(${sectionIdx}, '${tableName}', $2)`)
        },
        //"this.getValueOf('E8')"
        //inject "this.getValueOf(sectionIdx,'tableName', 'E8')"
        //value = 'E8'
        getValueOf(sectionIdx, tableName, value) {

            if (typeof value === 'string' && value.startsWith('this.'))
            {
                return eval(this.injectParams(value, sectionIdx, tableName));                
            }
            else
            {
                let rowIdx = this.getRowIdx(sectionIdx, tableName, value);
                let colIdx = this.getColIdx(sectionIdx, tableName, value);  
                let data;

                if (!rowIdx || !colIdx)
                    return;

                data = this.dataSource.sections[sectionIdx][tableName].data[rowIdx].values[colIdx];

                if (typeof data === 'string' && data.startsWith('this.'))
                {
                    return eval(this.injectParams(data, sectionIdx, tableName));                
                }
                else
                    return data;
            }
        },
        // value - "E7;F7;G7;this.getSumOf('J7;J8;this.Sum(\\'J7:H9\\')')""
        getSumOf(sectionIdx, tableName, value){
         
            let params = this.getFuncArrayParams(value);
            let sum = 0.0;

            for(var param of params)
            {
                if(param.length == 0)
                    continue;

                if(param && !isNaN(param))
                {
                    sum += parseFloat(param);
                }
                else if (param.startsWith('this.'))
                {
                    //formula
                    let result = eval(this.injectParams(param, sectionIdx, tableName));                    

                    if (result && !isNaN(result))
                    {
                        sum += result;
                    }
                }
                else if (param.includes(':'))                
                {
                    //range
                    let range = param.split(':');
                    if (range.length == 2)
                    {
                        let startRowIdx = this.getRowIdx(sectionIdx, tableName, range[0]);
                        let startColIdx = this.getColIdx(sectionIdx, tableName, range[0]); 

                        let endRowIdx = this.getRowIdx(sectionIdx, tableName, range[1]);
                        let endColIdx = this.getColIdx(sectionIdx, tableName, range[1]); 

                        if (startRowIdx > endRowIdx)
                        {
                            let temp = startRowIdx;
                            startRowIdx = endRowIdx;
                            endRowIdx = temp;
                        }

                        if (startColIdx > endColIdx)
                        {
                            let temp = startColIdx;
                            startColIdx = endColIdx;
                            endColIdx = temp;
                        }

                        for (let rowIdx = startRowIdx; rowIdx <=  endRowIdx; rowIdx++)
                            for (let colIdx = startColIdx; colIdx <=  endColIdx; colIdx++)
                            {
                                let data = this.dataSource.sections[sectionIdx][tableName].data[rowIdx].values[colIdx];
                                

                                if (typeof data === 'string' && data.startsWith('this.'))
                                {
                                    let result = eval(this.injectParams(data, sectionIdx, tableName));
                                    if (result && !isNaN(result))
                                    {
                                        sum += result; 
                                    }
                                }
                                else if(data && !isNaN(data))
                                {
                                    sum += parseFloat(data);
                                }
                            }
                    }
                }
                else
                {
                    //address
                    let rowIdx = this.getRowIdx(sectionIdx, tableName, param);
                    let colIdx = this.getColIdx(sectionIdx, tableName, param);  
                    let data = this.dataSource.sections[sectionIdx][tableName].data[rowIdx].values[colIdx];

                    if (typeof data === 'string' && data.startsWith('this.'))
                    {
                        let result = eval(this.injectParams(data, sectionIdx, tableName));
                        if (result && !isNaN(result))
                            sum += result; 
                    }
                    else if(data && !isNaN(data))
                        sum += parseFloat(data);
                }
            }


            return sum;
        },
        getColIdx(sectionIdx, tableName, currentCell) {
            function getColNumber(cell) {
                const colLetters = cell.match(/[A-Z]+/)[0];
                let colNumber = 0;
                for (let i = 0; i < colLetters.length; i++) {
                    colNumber = colNumber * 26 + (colLetters.charCodeAt(i) - 'A'.charCodeAt(0) + 1);
                }
                return colNumber - 1;
            }
            
            const baseColIdx = getColNumber(this.dataSource.sections[sectionIdx][tableName].exportStartCell);
            const currentColIdx = getColNumber(currentCell);
            
            return currentColIdx - baseColIdx;
        },        
        getRowIdx(sectionIdx, tableName, value)
        {
            let rowAddr = value.match(/\d+/)[0];
            let firstRowCell = this.dataSource.sections[sectionIdx][tableName].exportStartCell.match(/\d+/)[0]

            if (firstRowCell && !isNaN(firstRowCell) && rowAddr && !isNaN(rowAddr))
            {
                let rowIndex = parseInt(rowAddr);
                let firstRowIndex = parseInt(firstRowCell);
                return rowIndex - firstRowIndex;
            }      
            
            return null;
        }
    },
    async created() {
        await this.update();
        this.contBox.addEventListener('scroll', this.scrollContBoxMethod);
    },
    mounted() {        
        this.$eventBus.$on('update-active-tab', async () => {
            if (this.$parent.isActive)
                await this.update();
        });
        this.$nextTick(this.heightControlMethod);
        window.addEventListener('resize', this.heightControlMethod);        
    },
    destroyed() {
        this.$eventBus.$off('update-active-tab');
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.heightControlMethod);
        this.contBox.removeEventListener('scroll', this.scrollContBoxMethod);
    },
    watch: {
        dataSource: {
            handler(oldVal, newVal) {
                this.dataSource.sections.forEach((section, sectionIndex) => {
                    // eslint-disable-next-line no-unused-vars
                    Object.entries(section).filter(([key, item]) => {                        
                        return Object.prototype.hasOwnProperty.call(item, 'when');
                    }).forEach(([key, item]) => {
                        
                        let value = item.value === null ? 'null' : item.value;  

                        if (Object.prototype.hasOwnProperty.call(item.when, value))                          
                        if (!this.$options.oldVal || this.$options.oldVal.sections[sectionIndex][key].value != value)
                        {                            
                            this.$nextTick(function() { 
                                Object.keys(item.when[value]).forEach(k => {
                                    Object.keys(item.when[value][k]).forEach(c => {
                                        let paramValue = item.when[value][k][c];
                                        this.dataSource.sections.forEach((s, sIndex) => {
                                            // eslint-disable-next-line no-unused-vars
                                            Object.entries(s).forEach(([key, item]) => {
                                                if (key == k)                                                
                                                    this.updateDataSource({ property: `sections[${sIndex}].${k}.${c}`, value: paramValue });
                                                });                                            
                                        });                                    
                                    });
                                });
                            });
                        }
                    });
                });
                
                this.$options.oldVal = JSON.parse(JSON.stringify(newVal));
            },
            deep: true
        }
    }
}
</script>

<style scoped>
    .cloud-file-list-box{
        position: sticky;
        top: 15px;
    }   
</style>
